// extracted by mini-css-extract-plugin
export const root = "FirstScreen-module--root--2mBv2";
export const trangleFirst = "FirstScreen-module--trangleFirst--2FyfV";
export const trangleSecond = "FirstScreen-module--trangleSecond--3D8FH";
export const imageWrapper = "FirstScreen-module--imageWrapper--37MFB";
export const image = "FirstScreen-module--image--33Rb6";
export const imageInner = "FirstScreen-module--imageInner--2w7P7";
export const row = "FirstScreen-module--row--31Mzo";
export const link = "FirstScreen-module--link--1dHEA";
export const header = "FirstScreen-module--header--1oHn_";
export const icon = "FirstScreen-module--icon--2Srsx";