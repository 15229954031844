import React from "react"
import cx from "classnames"
import parse from "html-react-parser"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"

import * as s from "./Content.module.sass"

type ContentProps = {
  className?: string
  description: string
  title: string
  content: string
}

export const Content: React.FC<ContentProps> = ({
  className,
  description,
  title,
  content,
}) => (
  <section className={cx(s.root, className)}>
    <Container>
      <Row className={s.row}>
        <div className={s.inner}>
          <p className={s.description}>{description}</p>
          <h2 className={s.header}>{title}</h2>
          <div className={s.content}>{parse(content)}</div>
        </div>
      </Row>
    </Container>
  </section>
)
