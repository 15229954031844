import React, { ReactNode } from "react"
import cx from "classnames"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import { LinkToForm } from "@components/common/LinkToForm"
import TranglesFirst from "@icons/secondaryPages/SecondaryDesign1.svg"
import TranglesSecond from "@icons/secondaryPages/SecondaryDesign2.svg"

import * as s from "./FirstScreen.module.sass"

type FirstScreenProps = {
  className?: string
  title: string
  image: IGatsbyImageData
  icon: ReactNode
}

export const FirstScreen: React.FC<FirstScreenProps> = ({
  className,
  title,
  image,
  icon,
}) => {
  return (
    <section className={cx(s.root, className)}>
      <div className={s.imageWrapper}>
        <div className={s.image}>
          <div className={s.imageInner}>
            <GatsbyImage image={image} alt={title} style={{ height: "100%" }} />
          </div>
        </div>
        <TranglesSecond className={s.trangleSecond} />
      </div>
      <TranglesFirst className={s.trangleFirst} />
      <Container>
        <Row className={s.row}>
          <LinkToForm className={s.link} />
          <div className={s.icon}>{icon}</div>
          <h1 className={s.header}>{title}</h1>
        </Row>
      </Container>
    </section>
  )
}
