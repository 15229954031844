import React, { ReactNode } from "react"
import { getImage, getSrc, IGatsbyImageData } from "gatsby-plugin-image"

import { BaseLayout } from "@layouts/BaseLayout"
import { FirstScreen } from "@components/secondaryPages/FirstScreen"
import { Content } from "@components/secondaryPages/Content"

type PageInner = {
  pageContext: {
    langKey: string
    slug: string
  }
  location: Location
  image: IGatsbyImageData
  content: {
    title: string
    description: string
    subtitle: string
    content: string
  }
  icon: ReactNode
}

export const PageInner: React.FC<PageInner> = ({
  pageContext,
  location,
  image,
  content,
  icon,
}) => {
  // @ts-ignore
  const imageFile = getImage(image)!
  // @ts-ignore
  const imageURL = getSrc(image)

  return (
    <BaseLayout
      pageContext={pageContext}
      location={location}
      title={content.title}
      description={content.description}
      image={imageURL}
    >
      <FirstScreen title={content.title} image={imageFile} icon={icon} />
      <Content
        description={content.description}
        title={content.subtitle}
        content={content.content}
      />
    </BaseLayout>
  )
}
